import { useEffect, useState } from 'react';
import { prepareUrl } from '#utils/prepareUrl';

type Props = {
  currentLanguage: string;
  text: string;
  link: string;
  style: string;
};

const PolicyLink = ({ currentLanguage, text, link, style }: Props) => {
  const [policyLink, setPolicyLink] = useState('');

  useEffect(() => {
    setPolicyLink(prepareUrl(link, currentLanguage));
  }, [link, currentLanguage]);

  return (
    <a className={style} href={policyLink}>
      {text}
    </a>
  );
};

export default PolicyLink;
